<template>
  <div class="circular-score" :style="cssProps">
    <svg
      width="95%"
      height="95%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      class="align-center"
    >
      <circle
        class="progress-circle__base"
        cx="18"
        cy="18"
        r="15.91549430918954"
      ></circle>

      <circle
        class="progress-circle__progress"
        :style="cssProps"
        :stroke-dasharray="dashArray"
        stroke-dashoffset="25"
        cx="18"
        cy="18"
        r="15.91549430918954"
      ></circle>

      <text
        x="39%"
        y="54%"
        text-anchor="middle"
        dominant-baseline="middle"
        class="progress-circle__text"
        :style="cssProps"
      >
        {{ percentage }}
      </text>
      <text x="62%" y="64.5%" class="percent">%</text>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    maxScore: { type: Number, required: false, default: 100 },
    score: { type: Number, required: true, default: 0 }
  },
  computed: {
    percentage() {
      return ((this.score / this.maxScore) * 100).toFixed(0)
    },
    remaining() {
      return 100 - this.percentage
    },
    dashArray() {
      return `${this.percentage} ${this.remaining}`
    },
    cssProps() {
      return {
        '--fill': 'green'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.circular-score {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 2px;
}

.progress-circle {
  position: relative;
}

.progress-circle > svg {
  display: block;
  max-width: 120px;
}

.progress-circle__text {
  fill: var(--fill);
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: -0.1em;
  font-variant-numeric: proportional-nums;
}

.percent {
  fill: var(--fill);
  font-size: 0.8em;
}

.progress-circle__base {
  fill: none;
  stroke: darkgrey;
  stroke-width: 4;
  stroke-linecap: square;
}

.progress-circle__progress {
  fill: transparent;
  stroke: var(--fill);
  stroke-width: 4;
  stroke-linecap: butt;
  transition: stroke-dasharray 0.4s ease-in-out;
}
</style>
