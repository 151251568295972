<template>
  <div class="pa-3">
    <div class="d-print-none" style="display: flex;">
      <v-row class="mb-6 flex-nowrap match-activities__header" no-gutters>
        <v-col cols="7" class="d-flex align-center pl-0 flex-grow-1 match-activities__modes subtitle-2">
          <v-btn-toggle mandatory color="primary" class="mt-0 pt-0">
            <v-btn small @click="toggleImplicit(false)">{{ $t('activities.explicit') }}</v-btn>
            <v-btn small @click="toggleImplicit()">{{ $t('activities.implicit') }}</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="2" class="justify-end subtitle-2 d-flex align-center match-activities__value pr-4">
          {{ $t('global.offer') }}
        </v-col>
        <v-col cols="1" class="text-center d-flex align-center pr-1 match-activities__icon"></v-col>
        <v-col cols="2" class="subtitle-2 d-flex align-center pl-2 match-activities__value">
          {{ $t('global.application') }}
        </v-col>
      </v-row>
      <div v-if="!isImplicitMode" class="match-activities__spacer" />
    </div>

    <v-row>
      <v-col class="pa-0 mb-16">
        <v-card v-if="match" elevation="2" class="divided-grid-list divided-grid-list--panels">
          <MatchImplicitActivityRows v-show="isImplicitMode === true" :selections="getMatchImplicitActivities" />
          <MatchExplicitActivityRows v-show="isImplicitMode === false" :selections="getMatchExplicitActivities" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapState, mapGetters} from "vuex";
import MatchExplicitActivityRows from "@/components/matching/MatchExplicitActivityRows.vue";
import MatchImplicitActivityRows from "@/components/matching/MatchImplicitActivityRows.vue";

export default {
  components: {MatchImplicitActivityRows, MatchExplicitActivityRows},
  data() {
    return {
      isImplicitMode: false,
      isWithInterest: false,
    }
  },
  computed: {
    ...mapState("matching", ["match"]),
    ...mapGetters("matching", ["getMatchImplicitActivities", "getMatchExplicitActivities"]),
    score() {
      if (this.match) {
        if (this.isImplicitMode) {
          return Math.floor(this.match.implicitScore * 100) / 100
        }
        if (this.isWithInterest) {
          return this.match.scoreWithInterest * 100
        }
        return this.match.scoreWithoutInterest * 100
      }
      return 0
    },
  },
  methods: {
    matchIcon(selection) {
      if (selection.frequency !== null && selection.interest !== null) {
        return {
        'name': "mdi-checkbox-marked-circle",
        'color': 'success'
      }
      }
      return {
        'name': "mdi-close-circle",
        'color': 'error'
      }
    },
    toggleImplicit(toImplicit = true) {
      this.isImplicitMode = toImplicit
    }
  }
}
</script>
