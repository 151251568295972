<template>
  <v-card class="pa-4 pa-md-5" style="height: 100%">
    <h3>{{ $t('matching.status') }}</h3>

    <v-container v-if="match" class="mt-0 pb-0">

      <!-- offer status -->
      <v-row class="flex-nowrap" no-gutters>
        <v-col align-self="center" cols="5" class="py-2 px-0 pr-md-3">
          {{ $t( `global.offer`) }}
        </v-col>
        <v-col align-self="center" cols="6" class="py-2 px-0 pr-md-3 text-right text--secondary text-truncate">
          <a v-if="offerStatus.link" @click="$router.push(`/matching/${offerStatus.link}`)">
            {{ offerStatus.label }}
          </a>
          <span v-else>{{ offerStatus.label }}</span>
        </v-col>
        <v-col align-self="center" cols="1" class="py-2 px-0 text-right">
          <v-icon start class="pr-0" :color="offerStatus.color">{{ offerStatus.icon }}</v-icon>
        </v-col>
      </v-row>

      <!-- application status -->
      <v-row class="mt-0 flex-nowrap" no-gutters>
        <v-col align-self="center" cols="5" class="pa-0 pr-md-3">
          {{ $t('global.application') }}
        </v-col>
        <v-col align-self="center" cols="6" class="pa-0 pr-md-3 text-right text--secondary text-truncate">
          <a v-if="applicationStatus.link" @click="$router.push(`/matching/${applicationStatus.link}`)">
            {{ applicationStatus.label }}
          </a>
          <span v-else>{{ applicationStatus.label }}</span>
        </v-col>
        <v-col align-self="center" class="pa-0 text-right">
          <v-icon class="pr-0" :color="applicationStatus.color">{{ applicationStatus.icon }}</v-icon>
        </v-col>
      </v-row>

      <v-row class="mt-3 align-center">
        <v-col class="pr-0 flex-grow-0" style="width: 50px">
          <v-icon class="pr-2 py-0" :color="matchStatus.color" align-self="center">{{ matchStatus.icon }}</v-icon>
        </v-col>
        <v-col class="pl-1">
          {{ matchStatus.label }}
          <p v-if="Array.isArray(matchStatus.agent)" class="text--secondary pt-2 mb-0">
            {{ $t('matching.both_reserved_offer', {'agent': matchStatus.agent[0], 'date': formatDate(matchStatus.at[0])}) }}<br>
            {{ $t('matching.both_reserved_application', {'agent': matchStatus.agent[1], 'date': formatDate(matchStatus.at[1])}) }}<br>
          </p>
          <p v-else-if="matchStatus.agent" class="text--secondary pt-2 mb-0">
            {{ $t('global.date') }}: {{ formatDate(matchStatus.at) }}<br>
            {{ $t('global.agent') }}: {{ matchStatus.agent }}<br>
          </p>
        </v-col>
      </v-row>
      <v-row v-if="canRelease || canReserve">
        <v-col align-self="center" class="text-center">
          <v-btn v-if="canRelease" color="primary" @click="releaseMatch(match.id)" >
            {{ $t('matching.releaseCTA') }}
          </v-btn>
          <v-btn v-if="canReserve" color="primary" @click="reserveMatch({matchId: match.id})" >
            {{ $t('matching.reserveCTA') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import {formatDate} from "@/helpers/dates";

export default {
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    ...mapState('matching', ['match']),
    canRelease() {
      return this.match?.reservedById === this.$store.state.auth.user.id;
    },
    canReserve() {
      return !this.match?.reservedById && !this.match?.jobOffer.reservedByName && !this.match?.jobApplication.reservedByName;
    },
    matchStatus() {
      // Current Match reserved
      if (this.match?.reservedBy) {
        return {
          'icon': 'mdi-lock-check',
          'color': 'success',
          'label': this.$t('matching.already_reserved'),
          'agent': this.match?.reservedBy,
          'at': this.match?.reservedAt
        }
      }

      if (this.match?.jobOffer.reservedByName) {
        if (this.match?.jobApplication.reservedByName) {
          return {
            'icon': 'mdi-lock-alert',
            'color': 'default',
            'label': this.$t('matching.both_reserved_elsewhere'),
            'agent': [
              this.match?.jobOffer.reservedByName,
              this.match?.jobApplication.reservedByName
            ],
            'at': [
              this.match?.jobOffer.reservedAt,
              this.match?.jobApplication.reservedAt
            ]
          }
        }
        return {
          'icon': 'mdi-lock-alert',
          'color': 'default',
          'label': this.$t('matching.reserved_for_offer'),
          'agent': this.match?.jobOffer.reservedByName,
          'at': this.match?.jobOffer.reservedAt
        }
      }

      if (!this.match?.jobOffer.reservedByName && this.match?.jobApplication.reservedByName) {
        return {
          'icon': 'mdi-lock-alert',
          'color': 'default',
          'label': this.$t('matching.reserved_for_application'),
          'agent': this.match?.jobApplication.reservedByName,
          'at': this.match?.jobApplication.reservedAt
        }
      }

      return {'icon': 'mdi-lock-open-plus', 'color': 'success', 'label': this.$t('matching.match_available')}
    },
    offerStatus() {
      if (this.match?.id === this.match?.jobOffer?.reservedMatchId) {
        return (
          {'color': 'success', 'icon': 'mdi-check-circle', 'label': this.$t('matching.reserved')}
        )
      }
      return (
        this.match?.jobOffer?.reservedByName ?
          {
            'color': 'deep-orange',
            'icon': 'mdi-lock',
            'label': this.$t('matching.see_reservation'),
            'link': this.match?.jobOffer.reservedMatchId
          } :
          {'color': 'success', 'icon': 'mdi-circle', 'label': this.$t('matching.available')}
      )
    },
    applicationStatus() {
      if (this.match?.id === this.match?.jobApplication?.reservedMatchId) {
        // Reserved in this Match
        return (
          {'color': 'success', 'icon': 'mdi-check-circle', 'label': this.$t('matching.reserved')}
        )
      }
      return (
        this.match?.jobApplication?.reservedByName ?
          // Reserved in another Match
          {
            'color': 'deep-orange',
            'icon': 'mdi-lock',
            'label': this.$t('matching.see_reservation'),
            'link': this.match?.jobApplication.reservedMatchId
          } :
          // Not reserved
          {'color': 'success', 'icon': 'mdi-circle', 'label': this.$t('matching.available')}
      )
    }
  },
  methods: {
    formatDate,
    ...mapActions('matching', ['releaseMatch', 'reserveMatch']),
  },
}
</script>
