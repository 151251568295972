<template>
  <div class="match-activities">
    <div style="display: flex">
      <v-row class="pl-4 pr-0 flex-nowrap" no-gutters>
        <v-col cols="5" class="flex-grow-1 match-activities__name"></v-col>
        <v-col
          cols="2"
          class="text--secondary text-right pr-0 match-activities__value flex-shrink-0 justify-end"
        >
          {{ $t('matching.effort') }}
          <span class="body-2">
            {{
              $t('matching.effort_help', {
                'scale_min': match?.effortScale[0], 'scale_max':
                  match?.effortScale[1]
              })
            }}
          </span>
        </v-col>
        <v-col
          cols="2"
          class="text--secondary text-right pr-3 match-activities__value flex-shrink-0 text-truncate"
          >{{ $t('global.frequency') }}
        </v-col>
        <v-col
          cols="1"
          class="justify-center d-flex align-center match-activities__icon match-activities__icon--spacer flex-shrink-0"
          >&nbsp;</v-col
        >
        <v-col cols="2" class="text--secondary pl-3 match-activities__value flex-shrink-0 d-print-none">
          {{ $t('matching.interest') }}
        </v-col>
      </v-row>
      <div class="match-activities__spacer" />
    </div>

    <v-expansion-panels accordion focusable :tile="true">

      <v-expansion-panel
        v-for="(selection, row_index) in selections"
        :key="row_index"
        :readonly="!selection.is_name_overriden"
      >

        <v-expansion-panel-header class="pa-0 v-expansion-panel-header--explicit-activities">
          <v-row class="pl-4 pr-0 flex-nowrap match-activities__row" no-gutters>
            <v-col
              cols="6"
              class="d-flex align-center flex-grow-1 match-activities__name px-0"
              >{{ selection.display_name }}</v-col
            >
            <v-col cols="1" class="d-flex align-center justify-end pr-3">
              <span v-if="selection.effort">{{ selection.effort }}</span>
              <span v-else>-</span>
            </v-col>
            <v-col
              cols="2"
              class="justify-end pr-3 d-flex align-center match-activities__value"
            >
              <template v-if="selection.frequency !== null">
                <v-icon
                  v-for="index in 3"
                  :key="index"
                  :color="selection.frequency >= index ? `primary` : ``"
                >
                  <template v-if="selection.frequency >= index"
                    >mdi-briefcase-variant</template
                  >
                  <template v-else>mdi-briefcase-variant-outline</template>
                </v-icon>
              </template>
              <template v-else>
                <v-icon :title="$t(`matching.not_selected`)"
                  >mdi-minus-circle-outline</v-icon
                >
              </template>
            </v-col>

            <v-col
              cols="1"
              class="background justify-center d-flex align-center match-activities__icon"
            >
              <v-icon v-if="isIconVisible(selection)" class="ma-1" :color="matchIcon(selection).color">{{
                matchIcon(selection).name
              }}</v-icon>
            </v-col>

            <v-col cols="2" class="pl-3 d-flex align-center match-activities__value d-print-none">
              <template v-if="selection.interest">
                <v-icon
                  v-for="index in 3"
                  :key="index"
                  :color="selection.interest >= index ? `primary` : ``"
                >
                  <template v-if="selection.interest >= index">mdi-heart</template>
                  <template v-else>mdi-heart-outline</template>
                </v-icon>
              </template>
              <template v-else>
                <v-icon :title="$t(`matching.not_selected`)"
                  >mdi-minus-circle-outline</v-icon
                >
              </template>
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-icon v-if="selection.is_name_overriden">
              $expand
            </v-icon>
            <v-icon v-else color="background">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="background d-print-none">
          <div v-if="selection.is_name_overriden" class="text--secondary body-2">
            <div v-if="selection.name_in_offer">
              <p class="mb-1 font-weight-medium">
                Nom contextualisé dans l'offre:
              </p>
              <p>
                {{ selection.name_in_offer }}
              </p>
            </div>
            <div v-if="selection.name_in_application">
              <p class="mb-1 font-weight-medium">
                Nom contextualisé dans la demande:
              </p>
              <p>
                {{ selection.name_in_application }}
              </p>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['selections'],
  data() {
    return {}
  },
  computed: {
    ...mapState('matching', ['match'])
  },
  methods: {
    matchIcon(selection) {
      if (selection.frequency !== null && selection.interest !== null) {
        return {
          name: 'mdi-checkbox-marked-circle',
          color: 'success'
        }
      }
      return {
        'name': "mdi-close-circle",
        'color': 'error'
      }
    },
    isIconVisible(selection) {
      return selection.frequency !== null || selection.interest === null
    }
  }
}
</script>
