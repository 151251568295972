<template>
  <v-row class="">
    <v-col cols="12" class="px-3">
      <v-card class="fill-height pa-5 print-no-h-spacing">
        <v-row justify="center">
          <v-col md="2" class="text-left d-print-none">
            <p class="text-h5" data-cy="candidate_name_header">{{ match?.candidate.first_name }} {{ match?.candidate.last_name }}</p>
            <p class="text--secondary">
              <a @click="goToCandidate(match?.candidate.id)">
              {{ $t('matching.candidate_id') }} <span class="font-weight-medium">{{ match?.candidate.id }}</span>
              </a>
              <br>
              <a @click="goToApplication(match?.jobApplication.id)">
              {{ $t('matching.job_application_id') }} <span class="font-weight-medium">{{ match?.jobApplication.id }}</span>
              </a>
              <br>
              <span>
                {{ $t('global.date') }}: <span class="font-weight-medium">{{ formatDate(match?.jobApplication.createdAt) }}</span>
                <br>
                {{ $t('global.agent') }}: <span class="font-weight-medium">
                {{ match?.jobApplication.agentName }}
                </span>
              </span>
            </p>
          </v-col>
          <v-col>
            <p class="text-h5" data-cy="job_offer_name_header">{{ match?.jobOffer.name }} - {{ match?.employerName }}</p>
            <p class="d-none d-print-block mb-1">{{ $t('matching.job_application_id') }} <span class="font-weight-medium">{{ match?.jobApplication.id }}</span></p>
            <p class="d-none d-print-block mb-0">{{ $t('matching.job_offer_id') }} <span class="font-weight-medium">{{ match?.jobOffer.id }}</span></p>

            <p class="text--secondary d-print-none">
              <a @click="goToOffer(match?.jobOffer.id)">
              {{ $t('matching.job_offer_id') }} <span class="font-weight-medium">{{ match?.jobOffer.id }}</span>
              </a><br>
              <span>
                {{ $t('global.date') }}: <span class="font-weight-medium">{{ formatDate(match?.jobOffer.createdAt) }}</span>
                <br>
                {{ $t('global.agent') }}: <span class="font-weight-medium">
                {{ match?.jobOffer.agentName }}
                </span>
              </span>
            </p>
          </v-col>
          <v-col md="5" offset-md="1" class="d-print-none">
            <div class="d-flex align-center justify-space-between mb-6">
              <div>
                <span class="subtitle-1 font-weight-bold">{{ $t('matching.relevance') }}</span><br>
                <span class="caption">Les activités maîtrisées augmentent le score. Les activités non-maitrisées
                  impactent sur le score en fonction de l'effort de formation (si l'effort est
                  faible, alors le score peut même augmenter).</span>
              </div>
              <div class="ml-4" style="max-width: 70px;">
                <CircularScore :score="score" data-cy="matching_score_header"/>
              </div>
            </div>
            <div class="d-flex body-2 align-center justify-space-between">
              <div>Tenant compte de l'intérêt :</div>
              <div class="ml-4" style="max-width: 55px;">
                <CircularScore :score="scoreWithInterest" data-cy="matching_score_header"/>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>

</template>
<script>

import {mapState} from "vuex";
import {formatDate} from "@/helpers/dates";
import CircularScore from "@/components/common/CircularScore.vue";
import RouterMixin from "@/mixins/routerMixin";

export default {
  components: {CircularScore},
  mixins: [RouterMixin],
  computed: {
    ...mapState('matching', ['match']),
    score() {
      return ((this.match) ? this.match.scoreWithoutInterest : 0) * 100
    },
    scoreWithInterest() {
      return ((this.match) ? this.match.scoreWithInterest : 0) * 100
    },
  },
  methods: {
    formatDate,
    goToOffer(offerId) {
      this.routerPush({
        name: 'offerDetails',
        params: { id : offerId }
      })
    },
    goToApplication(applicationId) {
      this.routerPush({
        name: 'jobApplication',
        params: { id : applicationId }
      })
    },
    goToCandidate(candidateId) {
      this.routerPush({
        name: 'candidateDetails',
        params: { id : candidateId }
      })
    }
  }
}
</script>
