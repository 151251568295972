<template>
  <div class="">
    <v-row
      v-for="(selection, index) in selections"
      :key="index"
      class="px-4 flex-nowrap"
      no-gutters
    >
      <v-col cols="7" class="d-flex align-center flex-grow-1 match-activities__name">{{ selection.name }}</v-col>
      <v-col cols="2" class="d-flex align-center justify-end pr-3 match-activities__value">
        <v-icon :color="offerIcon(selection).color">{{
          offerIcon(selection).name
        }}</v-icon>
      </v-col>
      <v-col cols="1" class="d-flex align-center background justify-center match-activities__icon">
        <v-icon class="ma-1" :color="matchIcon(selection).color">{{
          matchIcon(selection).name
        }}</v-icon>
      </v-col>
      <v-col cols="2" class="d-flex align-center pl-3 match-activities__value">
        <v-icon :color="applicationIcon(selection).color">{{
          applicationIcon(selection).name
        }}</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    selections: {
      type: Array,
      required: false,
      default: Array
    }
  },
  data() {
    return {}
  },
  computed: {
    trueIcon() {
      return {
        name: 'mdi-checkbox-marked-circle',
        color: 'success'
      }
    },
    falseIcon() {
      return {
        'name': "mdi-close-circle",
        'color': 'error'
      }
    },
    minusIcon() {
      return {
        name: 'mdi-minus-circle',
        color: ''
      }
    }
  },
  methods: {
    offerIcon(selection) {
      return selection.offer ? this.trueIcon : this.minusIcon
    },
    applicationIcon(selection) {
      return selection.application ? this.trueIcon : this.minusIcon
    },
    matchIcon(selection) {
      return selection.offer && selection.application
        ? this.trueIcon
        : this.falseIcon
    }
  }
}
</script>
