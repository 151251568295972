<template>
  <v-card elevation="2" class="pa-4 pb-0 pa-md-5 pb-md-0" data-cy="match_availabilities_card" style="height: 100%">

    <h3 class="d-print-none">Disponibilités</h3>

    <div class="divided-grid-list">
      <v-row class="mb-2 flex-nowrap" no-gutters>
        <v-col cols="5" class="d-flex align-center pl-1 flex-grow-1 match-activities__name"></v-col>
        <v-col cols="3" class="text-right subtitle-2 pr-4">{{ $t('matching.required_offer') }}</v-col>
        <v-col cols="1" class="text-center match-activities__icon">&nbsp;</v-col>
        <v-col cols="3" class="subtitle-2 pl-2">{{ $t('matching.required_application') }}</v-col>
      </v-row>
      <v-row class="ma-0" no-gutters>
        <v-col cols="12"><v-divider class="ma-0"/></v-col>
      </v-row>
      <v-row class="px-3 flex-nowrap" no-gutters>
        <v-col cols="5" class="pt-3 d-flex align-center flex-grow-1 match-activities__name">{{ $t("global.startingDate") }}</v-col>
        <v-col cols="3" class="pt-3 d-flex align-center justify-end pr-3">
          <span v-show="jobOffer?.availableFrom">{{ formatDate(jobOffer?.availableFrom) }}</span>
          <v-btn v-show="!jobOffer?.availableFrom"
            icon small
            @click="$router.push(`/offers/${jobOffer?.id}/details#availableFrom`)"
          ><v-icon>mdi-pencil-circle</v-icon></v-btn>
        </v-col>
        <v-col cols="1" class="pt-3 d-flex align-center background justify-center match-activities__icon">
          <v-icon class="ma-1" :color="availableIcon.color" data-cy="match_icon">{{ availableIcon.name }}</v-icon>
        </v-col>
        <v-col cols="3" class="pt-3 d-flex align-center pl-3">{{ formatDate(jobApplication?.availability) }}</v-col>
      </v-row>

      <v-row class="px-3 flex-nowrap" no-gutters>
        <v-col cols="5" class="d-flex align-center flex-grow-1 match-activities__name">{{ $t("matching.rate") }}</v-col>
        <v-col cols="3" class="d-flex align-center justify-end pr-3">{{ jobOffer?.rate }} %</v-col>
        <v-col cols="1" class="background d-flex align-center justify-center match-activities__icon">
          <v-icon :color="rateIcon.color" class="ma-1" data-cy="match_icon">{{ rateIcon.name }}</v-icon>
        </v-col>
        <v-col cols="3" class="pl-3 d-flex align-center">{{ jobApplication?.rate }} %</v-col>
      </v-row>

      <v-row class="px-3 flex-nowrap" no-gutters>
        <v-col cols="5" class="d-flex align-center flex-grow-1 match-activities__name">{{ $t('global.irregularly_hours') }}</v-col>
        <v-col cols="3" class="d-flex align-center justify-end pr-3">
          {{ jobOffer?.irregularlyHours ? "Oui" : "Non" }}
        </v-col>
        <v-col cols="1" class="background d-flex align-center justify-center match-activities__icon">
          <v-icon :color="irregularlyHoursIcon.color" class="ma-1" data-cy="match_icon">{{ irregularlyHoursIcon.name }}</v-icon>
        </v-col>
        <v-col cols="3" class="pl-3 d-flex align-center">{{ jobApplication?.irregularlyHours ? "Oui" : "Non" }}</v-col>
      </v-row>

      <v-row class="px-3 flex-nowrap" no-gutters>
        <v-col cols="5" class="d-flex align-center flex-grow-1 match-activities__name">{{ $t('global.have_vehicle') }}</v-col>
        <v-col cols="3" class="d-flex align-center justify-end pr-3">
          {{ jobOffer?.vehicleNeeded ? "Oui" : "Non" }}
        </v-col>
        <v-col cols="1" class="background d-flex align-center justify-center match-activities__icon">
          <v-icon :color="vehicleIcon.color" class="ma-1" data-cy="match_icon">{{ vehicleIcon.name }}</v-icon>
        </v-col>
        <v-col cols="3" class="pl-3 d-flex align-center">
          {{ jobApplication?.hasVehicle ? "Oui" : "Non" }}
        </v-col>
      </v-row>

      <v-row class="px-3 flex-nowrap" no-gutters>
        <v-col cols="5" class="d-flex align-center flex-grow-1 match-activities__name">{{ $t("global.remote_possible") }}</v-col>
        <v-col cols="3" class="d-flex align-center justify-end pr-3">{{ jobOffer?.remotePossible ? "Oui" : "Non" }}</v-col>
        <v-col cols="1" class="background d-flex align-center justify-center match-activities__icon">
          <v-icon :color="remotePossibleIcon.color" class="ma-1" data-cy="match_icon">{{ remotePossibleIcon.name }}</v-icon>
        </v-col>
        <v-col cols="3" class="pl-3 d-flex align-center">{{ jobApplication?.remotePossible ? "Oui" : "Non"}}</v-col>
      </v-row>

      <v-row class="px-3 flex-nowrap" no-gutters>
        <v-col cols="5" class="pb-4 d-flex align-center flex-grow-1 match-activities__name">{{ $t("global.salary") }}</v-col>
        <v-col cols="3" class="pb-4 d-flex align-center justify-end pr-3">
          <span v-show="jobOffer?.minimumSalary">{{ formatChf(jobOffer?.minimumSalary) }}</span>
          <v-btn v-show="!jobOffer?.minimumSalary" icon small
            @click="$router.push(`/offers/${jobOffer?.id}/details#minimumSalary`)"
          ><v-icon>mdi-pencil-circle</v-icon></v-btn>
        </v-col>
        <v-col cols="1" class="pb-4 background d-flex align-center justify-center match-activities__icon">
          <v-icon :color="salaryIcon.color" class="ma-1" data-cy="match_icon">{{ salaryIcon.name }}</v-icon>
        </v-col>
        <v-col cols="3" class="pb-4 pl-3 d-flex align-center">
          <span class="hidden-print-only">
            {{ formatChf(jobApplication?.salaryClaim) }}
          </span>
          <span class="hidden-screen-only">
            {{ salaryStatus }}
          </span>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {formatDate} from "@/helpers/dates";
import formatChf from "@/helpers/currency";

export default {
  computed: {
    ...mapState("matching", ["match"]),
    jobOffer() {
      return (this.match?.jobOffer) ? this.match.jobOffer : null
    },
    jobApplication() {
      return (this.match?.jobApplication) ? this.match.jobApplication : null
    },
    minusIcon() {
      return {
        'name': "mdi-minus-circle",
        'color': ''
      }
    },
    successIcon() {
      return {
        'name': "mdi-checkbox-marked-circle",
        'color': 'success'
      }
    },
    errorIcon() {
      return {
        'name': "mdi-close-circle",
        'color': 'error'
      }
    },
    remotePossibleIcon() {
      // Remote availability success condition
      return (this.jobOffer?.remotePossible && !this.jobApplication?.remotePossible)
        ? this.errorIcon
        : this.successIcon
    },
    rateIcon() {
      // Job rate success condition
      return (this.jobApplication?.rate >= this.jobOffer?.rate)
        ? this.successIcon
        : this.errorIcon
    },
    irregularlyHoursIcon() {
      // Irregular hours availability/requirement success condition
      return (this.jobOffer?.irregularlyHours && !this.jobApplication?.irregularlyHours)
        ? this.errorIcon
        : this.successIcon
    },
    vehicleIcon() {
      // Vehicle requirement success condition
      return (this.jobOffer?.vehicleNeeded && !this.jobApplication?.hasVehicle)
        ? this.errorIcon
        : this.successIcon
    },
    salaryIcon() {
      // Salary claim & offer success condition
      if (!this.jobOffer?.minimumSalary || !this.jobApplication?.salaryClaim) {
        return this.minusIcon
      }
      if (this.jobOffer?.minimumSalary >= this.jobApplication?.salaryClaim) {
        return this.successIcon
      }
      return this.errorIcon
    },
    salaryStatus() {
      if (this.jobApplication?.salaryClaim <= this.jobOffer?.minimumSalary) {
        return 'OK'
      }
      return this.$t('global.to_be_negotiated')
    },
    availableIcon() {
      // Starting date success condition
      if (!this.jobOffer?.availableFrom || !this.jobApplication?.availability) {
        return this.minusIcon
      }
      if (this.jobOffer?.availableFrom >= this.jobApplication?.availability) {
        return this.successIcon
      }
      return this.errorIcon
    },
  },
  methods: {formatChf, formatDate},
}
</script>
