<template>
  <div>
    <PageTitle
      :title="`${$t('matching.title')} (${match?.id})`"
      back-button
      print-button
      icon="mdi-account"
      class="d-print-none"
    ></PageTitle>
    <MatchHeader/>
    <v-row class="">
      <v-col cols="12" md="4" class="mt-2 d-print-none">
        <MatchStatus />
      </v-col>
      <v-col cols="12" md="8" class="mt-2 pl-3 print-flex-none print-no-max-width">
        <MatchAvailabilities class="print-no-spacing"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MatchActivities/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PageTitle from "@/components/layout/PageTitle.vue";
import MatchHeader from "@/components/matching/MatchHeader.vue";
import MatchStatus from "@/components/matching/MatchStatus.vue";
import MatchAvailabilities from "@/components/matching/MatchAvailabilities.vue";
import MatchActivities from "@/components/matching/MatchActivities.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  components: {
    MatchActivities,
    MatchAvailabilities,
    MatchStatus,
    MatchHeader,
    PageTitle,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['id'],
  computed: {
    ...mapState('matching', ['match']),
    matchId() {
      return this.$route.params.id
    },
  },
  watch: {
    matchId: {
      handler(matchId) {
        this.getMatch(matchId)
      }
    }
  },
  mounted() {
    this.setManualDataIsLoading(true)
    this.getMatch(this.id).then(() =>
      this.setManualDataIsLoading(false)
    )
  },
  methods: {
    ...mapMutations('page', ['setManualDataIsLoading']),
    ...mapActions('matching', ['getMatch']),
  },
}
</script>
